@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Numans&display=swap');


/* ---------NewsPapersLogos-------- */
.newsPapersLogos-div{
    display: block;
    margin: 0px;
    width: 95%;
    margin-top: 20px;;
}
.newsPapersLogos-img{
    margin: 0px;
    width: 200px;
    height: 100px;
}
.newsPapers-imgDiv{
    width: 250px;
    height: 120px;
    margin-left: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    padding: 10px;
}

.reviews-Size{
    margin-right: 10px;
    height: 280px;
    width: auto;
    border: solid 1px;
    border-color: #d6d8d5;
    max-width: 300px;
}

@media (max-width: 500px){
    .newsPapersLogos-div{
        display: block;
    }
    .newsPapersLogos-img{
        width: 100px;
        height: 75px;
    }
}


/* ------------textImageComponent------------ */

.textImage-Container{
    display: flex;
    width: 85%;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 200;
    height: auto;
    margin-top: 30px;
}
.textImage-textContainer{
    flex: 2;
    display: flex;
    padding-top:10px;
    flex-direction: column;
    justify-content: center;
    padding-right: 3%;
}
.textImage-h1{
    width: 100%;
    height: auto;
    font-weight: 200;
    font-size: 35px;
}
.textImage-h2{
    font-size: 17px;
    font-weight: 200;
    width: 100%;
    line-height: 25px;
    height: auto;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}
.textImage-imageContainer{
    flex: 2;
    justify-content: center;
    align-content: center;
}
.textImage-image{
    width: 100%;
    height: auto;
    max-width: 100%;
}
.imagetext-button{
    width: 40%;
    font-size: 80%;
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;

}
.visible{
    animation: slideInTextImage 1.2s ease-out forwards;
}

@keyframes slideInTextImage {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }


  /* ------------imageTextComponent------------ */

  .imageText-Container{
    display: flex;
    height: auto;
    width: 85%;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 200;
    margin-top: 30px;
    
}
.imageText-textContainer{
    flex: 2;
    display: flex;
    padding-top:10px;
    flex-direction: column;
    padding-left: 3%;
    align-items: center;
    justify-content: center;
    order: 2;
}
.imageText-h1{
    width: 100%;
    height: auto;
    font-weight: 200;
    font-size: 35px;
}
.imageText-h2{
    font-size: 17px;
    font-weight: 200;
    width: 100%;
    height: auto;
    line-height: 25px;
    justify-content: center;
    align-content: center;
    overflow: hidden;

}
.imageText-imageContainer{
    flex: 2;
    justify-content: center;
    align-content: center;
    order: 1;
    margin-right: 10px;
}
.imageText-image{
    width: 100%;
    height: auto;
    max-width: 100%;
}
.visible{
    animation: slideInImageText 1.2s ease-out forwards;
}

  /* ------------galeriaCarousel------------ */

.arrow-next{
    transform: translate(-25px, -12px); 

}
.arrow-prev{
    transform: translate(25px, -12px); 

}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px; /* Ajusta el tamaño de la flecha */
  height: 30px; /* Ajusta la altura de la flecha, incrementando para hacerla más ancha */
  background-size: 100% 100%; /* Ajusta la imagen de fondo para ocupar todo el área */
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns%3d%27http%3a%2f%2fwww.w3.org%2f2000%2fsvg%27 fill%3d%27%23ffffff%27 viewBox%3d%270 0 8 8%27%3e%3cpath d%3d%27M4.707 7.707L1 4l3.707-3.707L5.707 1.707 3.414 4l2.293 2.293z%27%2f%3e%3c%2fsvg%3e');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns%3d%27http%3a%2f%2fwww.w3.org%2f2000%2fsvg%27 fill%3d%27%23ffffff%27 viewBox%3d%270 0 8 8%27%3e%3cpath d%3d%27M3.293 0.293L6 4 3.293 7.707 2.293 6.707 4.586 4 2.293 1.707z%27%2f%3e%3c%2fsvg%3e');
}


@keyframes slideInImageText {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 800px)
  {
    .imageText-h1{
        font-size: 25px;
    }
    .imageText-h2{
        font-size: 13px;
        line-height: 25px;
    }
    .textImage-h1{
        font-size: 25px;
    }
    .textImage-h2{
        font-size: 15px;
        line-height: 25px;
    }
  }

@media (max-width: 650px){
    .imageText-Container{
        display: flex;
        flex-direction: column;
    }
    .imageText-textContainer{
        order: 1;
    }
    .imageText-imageContainer{
        order: 2;
    }
    .imageText-h2{
        font-size: 17px;
        line-height: 15px;
        font-weight: 200;
    }
    .imageText-h1{
        font-weight: 400;
    }


    .textImage-Container{
        display: flex;
        flex-direction: column;
    }
    .textImage-h2{
        font-size: 17px;
        line-height: 15px;
        font-weight: 200;

    }
    .textImage-h1{
        font-weight: 400;
    }

}


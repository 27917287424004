@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Numans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');



/* --------HOME-------- */
.firstContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    font-family: "Montserrat", sans-serif;

}
.graceDesarrollo-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.grace-Container{
    display: flex;
    height: 390px;
    width: 85%;
    justify-content: center;
    margin-top: 40px;
}
.grace-textContainer{
    flex: 2;
    display: flex;
    padding-top:30px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.grace-h2-1{
    font-size: 17px;
    font-weight: 200;
    width: 80%;
    line-height: 25px;
    height: 70%;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
.grace-h2-2{
    font-size: 17px;
    font-weight: 600;
    font-style: italic;
}
.grace-h2-3{
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
}
.grace-firma{
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 80%;
}
.grace-imageContainer{
    flex: 2;
    justify-content: center;
    align-content: center;
}
.grace-image{
    width: 100%;
    height: auto;
    max-width: 100%;
}
.visible{
    animation: slideIn 1.2s ease-out forwards;
}


.desarrollo-div{
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: center;
    margin-top: 30px;
}
.desarrollo-titulo-div{
    display: flex;
    height: 93px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 100;
    font-style: italic;
    padding: 10px;
    margin: 20px;
}
.desarrollo-textMap-div{
    display: flex;
    flex-direction: row;
    height: auto;
    width: 90%;
    justify-content: center;
    
}

.desarrollo-text-div{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    font-size: 28px;
    font-weight: 200;
    font-style: italic;
    width: 90%;
}
.desarrollo-map-div{
    flex: 2;
    justify-content: center;
    align-content: center;

}
.desarrollo-p{
    font-size: 16px;
    margin: 0, 0, 14.4px;
    padding-top: 5px;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    font-weight: 300;

}




  /* ------------ENERGIA SUSTENTABLE------------ */
  .sustentable-div{
    display: flex;
    flex-direction: row;
    font-family: "Montserrat", sans-serif;
}
.sustentable-div-h1{
    flex: 0.8;
    align-content: center;
    font-size: 60px;
    font-weight: 200;
}
.sustentable-div-h3{
    flex: 1.2;
    font-size: 16px;
    font-weight: 300;
    align-content: center;
}
.ventajasCaracteristicas{
    display: flex;
    font-family: "Montserrat", sans-serif;
    margin-top: 50px;

}
.ventajas-div{
    flex: 1;
}

.caracteristicas-div{
    flex: 1;
}
.ventajasCaracteristicas-h2{
    font-size: 31px;
    font-weight: 200;
    padding-left: 5%
}
.ventajCaract-li{
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
}


/* --------------- GALERIA -------------- */
.galeria-div-1{
    display: flex;
    width: 85% ;
    flex-direction: row;
    padding-top: 20px;
}
.galeria-div-2{
    flex: 1;
    padding: 10px
}
.galeria-h3{
    font-size: 35px ;
    font-weight: 200 ;
    padding: 5px;
}
.visible{
    animation: slideInTextImage 1.2s ease-out forwards;
}

/* ----------- PRENSA ---------- */

.prensa-div-1{
    display: flex;
    width: 85% ;
    flex-direction: row;
    padding-top: 20px;
}
.prensa-div-img{
    flex: 1;
    padding: 10px
}
.prensa-img{
    width: 95%;
}
.prensa-h3{
    font-size: 40px;
    font-weight: 200;
    padding: 20px;
}


/* ----------- BARRIOS ---------- */
.masterplan-div{
display: flex; 
flex-direction: column;
align-content: center;
align-items: center;
width: 83%;
padding-top: 50px;
}

.masterplan-h1{
    padding: 10px;
    font-size: 50px;
    font-weight: 300;
    width: 90%;
    text-align: center;
}
.masterplan-p{
    width: 85%;
    font-size: 18px;
}
.masterplan-a{
    text-decoration: none;
     text-align: center;
     color: white; 
     background-color: black;
     padding: 5px;
     margin: 15px;
     width:  30%
}
.masterplan-gal-div{
    width: 95%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
}
.masterplan-gal-h1{
    padding-top: 10px;
    font-size: 50px;
    font-weight: 200;
    text-align: center;
}


/* ----------- VENTA LOTES ---------- */
.heroVentas{
    height:700px;
    min-height: 700px; 
    background-size: cover !important;
    background-position: center;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: space-around;
    width: 100% !important;
   }

   .hero-p{
    color: white;
    font-size: 57px;
    font-family: "Hind", sans-serif;
    font-weight: 700;
   }

   .hero-button{
    width: 150px;
    padding: 10px 20px;
    background-color: rgb(255, 255, 255, 0.5);
    border: none;
    color: #fff;
    border-radius: 10%;
   }
   .hero-link{
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
   }
   .imgText{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    font-family: "Hind", sans-serif;
    margin-top: 50px
   }

   .img-div{
    font-size: 37px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
   }
   .img-img{
    width: 100%;
    height: auto;
    max-width: 100%;
   }
   .text-div{
    flex: 1;
    position: relative;

   }
   .text-p{
    font-size: 16px;
    width: 70%;
    font-weight: 200;
    color: #8c8c8c
   }
   .text-p-grace{
    font-size: 47px;
    width: 70%;
    font-weight: 600;
    padding-bottom: 30px;

   }
   .text-p-sm{
    font-size: 13px;
    font-weight: 400;
    color: #8c8c8c;
   }

   .text-gracediv{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 40px;
    }

    .map-div{
        height:auto;
        background-size: cover !important;
        background-position: center;
        display: flex;
        flex-direction:row;
        position: relative;
        width: 100% !important;
        height: 475px;
        margin-top: 40px;
        font-family: "Hind", sans-serif;
        

    }
    .map-div-1{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

    }
    .map-div-2{
        flex: 1;
        padding:80px;
    }
    .h3-map{
        font-size: 37px;
        font-weight: 700;
        margin-bottom: -10px;
    }
    .cursiva{

        font-family: "Playfair Display", serif;
        font-style: italic;
    }
    .last-div{
        background-color: #8c8c8c;
        width: 100%;
        height: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center ;
        font-family: "Hind", sans-serif;


    }
    .last-div-h5{
        font-size: 31px;
        font-weight: 600;
        color: #fff;
    }
    .last-div-h5-2{
        font-size: 31px;
        font-weight: 600;
        color: #fff;
        font-family: "Playfair Display", serif;
        font-style: italic;
    }
    .last-div-h5-3{
        font-size: 16px;
        color: #fff;
        padding: 30px;
    }
    .fade-text {
        opacity: 0;
        transition: opacity 1s ease-in-out;
      }
      
      .fade-text.visible {
        opacity: 1;
      }
      
      .fade-text.hidden {
        opacity: 0;
      }












/* ----------- MEDIA ---------- */

@media (max-width: 1000px){
    .masterplan-mapdiv{
        display: none;
    }
}
@media (max-width:960px){
    .imgText{
        flex-direction: column;
    }
    .text-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
    }
    .last-div-h5{
        font-size: 20px;
    }
    .last-div-h5-2{
        font-size: 20px;;
    }
}

@media (max-width: 660px){
    .hero-p{
        font-size: 40px;
    }
    .map-div{
        height: 250px;
    }
    .h3-map{
        font-size: 15px;
    }
}

@media (max-width: 470px){
    .hero-p{
        font-size: 30px;
    }
    .last-div-h5{
        font-size: 15px;
    }
    .last-div-h5-2{
        font-size: 15px;
    }
    .last-div-h5-3{
        font-size: 11px;
        text-align: center;
    }
}


@media (max-width: 850px){
    .masterplan-h1{
        font-size: 30px;
    }
}
@media (max-width: 750px){
    .grace-Container{
       display: flex;
       flex-direction: column;
       height: auto;
       order: 2;
    }
    .grace-textContainer{
        display: flex;
        width: 100%;
        height: auto;
        order: 2;
    }
    .desarrollo-div{
        order: 1;
    }
    .grace-imageContainer{
        order: 1;
        width: 100%;
        
    }
    .desarrollo-textMap-div{
        display: flex;
        flex-direction: column;
        height: auto;
     }
     .galeria-div-1{
        display: flex;
        flex-direction: column;
     }
     .prensa-div-1{
        flex-direction: column;
    }

}

@media (max-width: 705px){
    .desarrollo-titulo-div{
        font-size: 20px;
    }
    .desarrollo-text-div{
        font-size: 20px;
    }
    .desarrollo-p{
        font-size: 12px;
    }
    .ventajasCaracteristicas{
        display: flex;
        flex-direction: column;
    
    }

}

@media (max-width: 890px){
    .sustentable-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
    }
    .sustentable-div-h1{
        font-size: 45px;
    }
}
@media (max-width: 500px){
    .galeria-h3{
        font-size: 25px;
        font-weight: 300;
    }
    .masterplan-a{
        width: 60%;
        font-size: 14px;
    }
}


/* --------- KEYFRAMES -------- */
@keyframes slideIn {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideInTextImage {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

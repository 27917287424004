@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* ---------FOOTER-------- */

.first-container-footer{
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    align-items: center;
}
.footer-div1{
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: center;
}
.footer-div1-1{
flex: 1;
flex-direction: column;
align-items: center;

}
.footer-div1-2{
flex: 1;
}
.footer-div1-3{
flex: 1;
}
.logocolor{
    width: 70%;
    height: auto;
}
.social-icons-div{
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-evenly;
    align-items: center;
    
}
.social-icons{
    font-size: 21px;
    color: #928d8d;
}

.footer-icons{
    font-size: 30px;
    margin-right: 10px;
}
.footer-div1-2-li{
    list-style: none;
}
.footer-div1-2-a{
    text-decoration: none;
    color: #2A2A2A;
    font-size: 14px;
    padding: 14px, 3px;
}
.footer-div1-2-a:hover{
    font-size: 12px;
    transition: 0.3s;
    padding: 25px;
    color: #464646
}
.footer-div1-2-dropbtn{
    height: 25px;
    display: none;

}
.footer-div1-3-divs{
    display: flex;
    flex-direction: row;
    width: 100%;
    

}
.footer-div1-3-divs-p{
    font-size: 14px;
}
.footer-div2{
    background-color: #b9b9b9;
    width: 100%;
    display: flex;
    margin: 0;
    padding: 5px;
    flex-direction: row;
    justify-content: center;
    color: #2A2A2A;
    font-size: 9px;
}
.footer-div2-p{
    font-size: 12px;
    padding: 5px;
    margin: 0px;
}


@media (max-width: 750px) {
    .footer-div1-2-dropbtn{
        display: block;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 60px;
    }
    .footer-div1-1{
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    .footer-div1-2-ul{
        display: none;
    }
    .footer-div1{
        display: block;
    }
}

@media (max-width: 500px){
    .footer-div1-3-divs-p{
        font-size: 12px;
    }
}
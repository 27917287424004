
.header-container{
    height: 212px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 0;
}
.header-subcontainer{
    width: 95%;
}

.img-logo{
    width: 190px;
    display: block;
}
.div-logo{
    width: 190px;
    display: block;
    margin-left: 40px;
}


.logoNav-Container{
    display: flex;
    flex-direction: row;
    height: 146px;
}
.div-logo{
    flex: 1.5;
    /* padding-left: 40px; */
    align-content: start;
    display: flex;
    justify-content: center; 
}
.navBar2-Container{
    justify-content: start;
    align-content: end;
    flex: 3;
}
.navBarMenu-1{
    justify-content: end;
    height: 12px;
    flex-wrap: nowrap;
}
.nav2{
    /* justify-content: start; */

}
.navBar-navs{
    list-style: none;
    display: flex;
    flex-direction: row;

}
.navBar1-linkItem {
    padding: 13px 12px;
    font-size: 12px;
    font-weight: 700;
}

.navBar1-a{
    text-decoration: none;
    color: #fff;
}
.navBar1-a:hover{
    text-decoration: underline white;
}
.navBar2-linkItem {
    text-decoration: none;
    padding: 13px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
}
.navBar2-linkItem:hover{
    text-decoration: underline white;

}
.dropbtn{
    color: #fff;
    height: 25px;
    display: none;

}


@media (max-width: 910px) {
    .dropbtn{
        display: block;
        margin: 0 auto;
        padding-top: 30px;
    }
    .navBar2-Container{
        display: none;
    }
    .div-logo{
      display: block;
      margin-left: 0%;
    }
    .logoNav-Container{
        display: block;
        height: 146px;
        margin: 0 auto;
    }
    .header-container{
        height: 299px;
    }

}

@media (max-width: 520px) {
.navBar1-linkItem{
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
   }
   .navBarMenu-1{
    width: 100%;
}}
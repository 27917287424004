.hero{
 height:700px;
 min-height: 700px; 
 background-size: cover !important;
 background-position: center;
 display: flex;
 flex-direction: column;
 position: relative;
 align-items: center;
 width: 100% !important;
 z-index: 9990;
}


.video-bg{
    height:700px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
}

.textContainer{
    width: 70%;
    padding-top: 20px;
    position:relative;
    flex-grow: 1; 


}

.texth2-1{
    color: #fff;
    font-size: 53px;
    margin-bottom: -20px;
    animation: slideInHero 1s ease-out forwards;


}
.texth2-2{
    color: #fff;
    font-weight: 300;
    font-size: 53px;
    animation: slideInHero 1.2s ease-out forwards;
}

.texth5{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    animation: slideInHero 1.1s ease-out forwards;
    padding-top: 20px;;
}

.arrow1{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    top: 50%;
    opacity: 0.8;
    transform: translate(-55px, -12px);
}
.arrow2{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    top: 50%;
    opacity: 0.8;
    transform: translate(55px, -12px);
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 15px; /* Tamaño de la flecha */
  height: 15px; /* Tamaño de la flecha */
}

.buttonHero{
    margin-top: 20px;;
    color: #fff;
    border-color: #fff;
}
.buttonHero:hover{
  border: none;
 background-color: rgba(0, 0, 0, 0.5);
  color:#fff;
}
.logoContainer{
    width: 500px;
    height:500px;
    display: flex;
    align-content: center;
    position: relative;
    margin-top: 10%;
}
.heroLogo{
    position: absolute;
    width: 100%;

}


@media (max-width: 520px) {
    .texth2-1{
        font-size: 29px;
        margin-bottom: -10px;
    }
    .texth2-2{
        font-size: 29px;

    }
    .texth5{
        font-size: 16px;
        font-weight: 200;
    }
    .textContainer{
        width: 80%;
    }
    .arrow1{
        transform: translate(-25px, -12px); 
    } 
    .arrow2{
        transform: translate(25px, -12px); 
    }
}
@keyframes slideInHero {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  
  


/* -------------HERO DEL VIDEO INSTITUCIONAL-------------- */


  .video-background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .video-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  
  .content-overlay {
    position: relative;
    z-index: 1; 
    color: white;
    text-align: center;
    padding: 20px;
  }
  .play-button {
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }